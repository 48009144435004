<script setup lang="ts">
const { t } = useT();
const route = useRoute();
const isGuest = useIsGuest();
const [expanded, toggleExpanded] = useToggle();
const { handleSupportButtonClick } = useSupportChat();
const { data: appInitData } = useAppInitData();
const { scrollToContent, content, offset } = useFeedbackForm();
const { feedback } = route.query;

const { seoData } = useHomePage();
const hasDeposits = computed(() => appInitData.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && appInitData.value?.isAffiliate);

const filterFooterLinks = (slug: string) => {
	const rules = [
		{ slugs: ["alternative-method-of-entry"], condition: () => isGuest.value },
		{ slugs: ["affiliate-agreement", "partners-rules"], condition: () => !isShowAffiliate.value },
		{ slugs: ["promotional-games-rules", "refund-policy"], condition: () => !hasDeposits.value }
	];

	return !rules.some((rule) => rule.slugs.includes(slug) && rule.condition());
};

const termsLinks = computed(() =>
	appInitData.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter((link) => link?.slug && filterFooterLinks(link?.slug))
);

const handleScroll = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	});
};

onMounted(() => {
	if (feedback) {
		setTimeout(() => {
			scrollToContent(offset.value);
		}, 50);
	}
});
</script>

<template>
	<footer>
		<AButton class="button-top" variant="secondary" data-tid="jump-to-top" @click="handleScroll">
			<NuxtIcon name="16/arrow-top" filled />
			<AText :modifiers="['uppercase']">{{ t("Take me up") }}</AText>
		</AButton>
		<section class="grid">
			<div class="help">
				<AText class="title" variant="toledo" :modifiers="['bold', 'uppercase']" as="div">{{ t("need help") }}?</AText>
				<div class="links-contact">
					<NuxtIcon name="20/chat" filled />
					<AText :modifiers="['link', 'underline']" data-tid="footer-support" @click="handleSupportButtonClick()">{{
						t("Online support")
					}}</AText>
				</div>
				<NuxtLink to="mailto:support@nolimitcoins.com" external class="links-contact" data-tid="footer-support-email">
					<NuxtIcon name="20/mail" filled />
					<AText :modifiers="['link', 'underline']">support@nolimitcoins.com</AText>
				</NuxtLink>

				<AText class="help-text" as="div">{{ t("US Payment Related Queries (24/7):") }}</AText>

				<NuxtLink to="tel:+12089745349" external class="links-contact" data-tid="footer-support-phone">
					<NuxtIcon name="20/phone" filled />
					<AText :modifiers="['link', 'underline']">{{ t("+1 (208) 974-5349") }}</AText>
				</NuxtLink>
				<ClientOnly>
					<div v-if="appInitData?.socialNetworks?.length" class="links-social">
						<NuxtLink
							v-for="social in appInitData?.socialNetworks"
							:key="social?.id"
							:to="social?.url"
							:data-tid="`footer-social-${social?.id}`"
							external
							target="_blank"
						>
							<span v-html="social?.icon" />
						</NuxtLink>
					</div>
				</ClientOnly>
			</div>
			<div class="description">
				<div class="icon">
					<NuxtLink
						class="icon-trustpilot"
						data-tid="footer-trustpilot"
						to="https://www.trustpilot.com/evaluate/nolimitcoins.com?stars=5"
						external
						target="_blank"
					>
						<NuxtImg src="/nuxt-img/footer/trust-txt.svg" loading="lazy" alt="trustpilot" width="180" height="45" />
						<div class="flex-center">
							<NuxtIcon v-for="(_, index) in 4" :key="index" name="16/star-1" filled />
							<NuxtIcon name="16/star-0" filled />
						</div>
					</NuxtLink>
					<NuxtImg src="/nuxt-img/footer/18.svg" loading="lazy" alt="age 18+" width="32" height="32" />
				</div>
				<div class="about">
					<AText class="title" variant="toledo" data-tid="footer-about" :modifiers="['bold', 'uppercase']" as="div">{{
						t("about nolimitcoins")
					}}</AText>
					<AText v-for="(_, index) in 4" :key="index" as="p">{{ t(`footer.txt${index + 1}`) }}</AText>
					<AText as="p">
						<i18n-t keypath="footer.txt5">
							<template #mail>
								<NuxtLink
									to="mailto:support@nolimitcoins.com"
									external
									class="links-contact"
									data-tid="footer-support-phone"
								>
									<AText :modifiers="['link', 'underline']">support@nolimitcoins.com</AText>
								</NuxtLink>
							</template>
							<template #tel>
								<NuxtLink to="tel:+12088261426" external class="links-contact" data-tid="footer-support-phone">
									<AText :modifiers="['link', 'underline']">+1 (208) 826-1426</AText>
								</NuxtLink>
							</template>
						</i18n-t>
					</AText>
				</div>
				<div v-if="route.path === '/'" class="seo" data-tid="footer-seo">
					<ACollapse :expanded="expanded">
						<template #title>
							<div :class="['seo-button', { active: expanded }]" @click="toggleExpanded()">
								<AText variant="toledo" :modifiers="['bold']">{{
									t("NoLimitCoins: Free to Play Social Casino")
								}}</AText>
								<NuxtIcon name="16/arrow-down-small" filled />
							</div>
						</template>
						<template #expanded>
							<div class="seo-text" v-html="seoData?.seoText" />
						</template>
					</ACollapse>
				</div>
			</div>

			<div ref="content" class="feedback" data-tid="footer-feedback">
				<ClientOnly>
					<LazyOFeedback />
				</ClientOnly>
			</div>
		</section>
		<section class="terms" data-tid="footer-terms">
			<NuxtImg
				class="logo"
				src="/nuxt-img/footer/logo-footer.png"
				loading="lazy"
				alt="logo"
				width="160"
				height="35"
				format="webp"
			/>
			<div class="terms-flex">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link.slug"
					class="links"
					:data-tid="`footer-terms-${index}`"
					:to="`/page${link?.url}`"
					external
				>
					<AText as="div">{{ link.title }}</AText>
				</NuxtLink>
			</div>
		</section>
		<AText class="copyright" data-tid="footer-copyright" :modifiers="['center']" as="div"
			>{{ appInitData?.footer?.copyright }} {{ t("NoLimitCoins Social Gaming Platform") }}</AText
		>
	</footer>
</template>

<style lang="scss" scoped>
footer {
	background: var(--a-header-background);
	backdrop-filter: var(--a-header-backdrop-filter);
	padding: 24px 32px;
	@include media-breakpoint-down(lg) {
		padding: 24px 16px;
		margin-bottom: 56px;
	}
	.button-top {
		@include media-breakpoint-down(lg) {
			margin: 0 auto 24px;
		}
		@include media-breakpoint-up(lg) {
			margin: 0 0 16px auto;
		}
		.nuxt-icon {
			margin-right: 12px;
		}
	}
	.title {
		margin-bottom: 24px;
	}
	p {
		line-height: 22px;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	.grid {
		@include media-breakpoint-down(lg) {
			margin-bottom: 16px;
			display: flex;
			flex-direction: column;

			> * {
				order: 3;
			}

			> .feedback {
				order: 2;
			}
		}
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-gap: 16px;
			grid-template-areas: "help description feedback";
			margin-bottom: 24px;
		}

		.description {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.icon {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					justify-content: space-between;
				}
			}

			@include media-breakpoint-up(lg) {
				> * {
					order: 2;
				}
				.about {
					order: 1;
				}
			}
		}
		.help {
			grid-area: help;
			@include media-breakpoint-up(lg) {
				width: 216px;
			}
			&-text {
				padding: 10px 0 4px;
			}
			.links-contact {
				display: flex;
				align-items: center;
				margin-bottom: 16px;
				transition: all 200ms ease-in-out;
				:hover {
					text-decoration: none;
					opacity: 0.85;
				}
				.nuxt-icon {
					font-size: 20px;
					margin-right: 8px;
				}
			}
		}
		.links-social {
			display: flex;
			gap: 12px;
			margin: 27px 0 16px;
			a {
				transition: all 200ms ease-in-out;
				&:hover {
					opacity: 0.85;
				}
			}
		}
		.about {
			grid-area: about;
			@include media-breakpoint-down(lg) {
				margin-top: 40px;
			}
		}
		.icon {
			grid-area: icons;
			@include media-breakpoint-down(lg) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			@include media-breakpoint-up(lg) {
				text-align: right;
			}
			&-trustpilot {
				margin-right: 65px;
			}
		}
		.seo {
			grid-area: seo;
			padding: 16px 0;
			&-button {
				display: flex;
				align-items: center;
				letter-spacing: -0.01962em;
				cursor: pointer;
				.nuxt-icon {
					display: inline-block;
					font-size: 24px;
					margin-left: 15px;
					transition: all 200ms ease-in-out;
				}
				&.active .nuxt-icon {
					transform: rotate(180deg);
				}
			}
			&-text {
				color: var(--custom-rgb-4);
				font-size: 14px;
				line-height: 1.35;
				font-weight: 300;
				margin-top: 8px;
				&:deep() {
					h2 {
						font-size: 28px;
						line-height: 1.167;
					}
					h3 {
						font-size: 24px;
						line-height: 1;
					}
					:is(h2, h3) {
						color: var(--neutral-100);
						font-weight: 500;
						margin-bottom: 20px;
					}
					p {
						text-align: justify;
						line-height: 1.375;
						margin-bottom: 24px;
					}
					ul {
						margin: 0 0 20px 14px;
						li {
							list-style-type: disc;
							line-height: 1.375;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.terms {
		border-top: 1px solid var(--custom-rgb-6);
		border-bottom: 1px solid var(--custom-rgb-6);
		padding: 17px 0;
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.logo {
			filter: grayscale(100%);
			@include media-breakpoint-down(lg) {
				display: block;
				margin: 0 auto;
			}
		}
		&-flex {
			@include media-breakpoint-up(lg) {
				display: flex;
				gap: 40px;
				margin-left: 72px;
			}
			.links {
				display: block;
				color: var(--custom-6);
				@include media-breakpoint-down(lg) {
					margin-top: 16px;
				}
				&:hover {
					text-decoration: underline;
					opacity: 0.85;
				}
			}
		}
	}
	.copyright {
		padding-top: 24px;
	}
}

a.icon-trustpilot {
	display: inline-flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 4px;

	img {
		object-fit: contain;
		width: 90px;
		height: 22px;
	}

	div {
		font-size: 24px;
		gap: 4px;
	}
}
</style>
